/* eslint-disable no-console */
// const sigUtil = require('../../node_modules/@metamask/eth-sig-util/dist/sign-typed-data');
const ConstantsCommon = require('../ConstantsCommon');

function Web3Helper() {
}

Web3Helper.getRawTxn = async (txnHexBase, includeSimOuput, estimatedGasFactorBp) => {
    const errMsg = 'Failed to get txn data';
    try {
        const accessTokenToUse = await window.authManager.checkRefreshGetAccessTokenAsync();
        const payload = {
            txnHexBase,
            includeSimOuput,
            estimatedGasFactorBp,
        };
        window.onTx();
        const response = await fetch(
            `${ConstantsCommon.API_URL}/txn/raw`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessTokenToUse}`,
                },
                body: JSON.stringify(payload),
            },
        );
        window.onRx();
        const statusCode = response.status;
        console.log(`${window.logPrefix()}getEip712AuthData statusCode [${statusCode}]`);
        if (statusCode === 200) {
            const txnToSend = await response.json();
            return txnToSend;
        }
        if (statusCode === 401) {
            window.authManager.handle401Redirect();
            throw new Error(errMsg);
        } else {
            // console.log(`${window.logPrefix()}${errMsg}`);
            // logError(response);
            const errMsgFinal = await window.authManager.showDataErrorToast(response, errMsg, true);
            throw new Error(errMsgFinal);
        }
    } catch (error) {
        console.log(`${window.logPrefix()}${errMsg}: ${error.stack || error.message}`);
        throw error;
    }
};

Web3Helper.getEip712AuthData = async (address, action) => {
    const errMsg = 'Failed to get auth data';
    try {
        const accessTokenToUse = await window.authManager.checkRefreshGetAccessTokenAsync();
        const payload = {
            action,
            address,
        };
        const qs = Qs.stringify(payload, { encode: false });
        window.onTx();
        const response = await fetch(
            `${ConstantsCommon.API_URL}/eip712/auth?${qs}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessTokenToUse}`,
                },
            },
        );
        window.onRx();
        const statusCode = response.status;
        console.log(`${window.logPrefix()}getEip712AuthData statusCode [${statusCode}]`);
        if (statusCode === 200) {
            const eip712AuthData = await response.json();
            return eip712AuthData;
        }
        if (statusCode === 401) {
            window.authManager.handle401Redirect();
            throw new Error(errMsg);
        } else {
            // console.log(`${window.logPrefix()}${errMsg}`);
            // logError(response);
            const errMsgFinal = await window.authManager.showDataErrorToast(response, errMsg, true);
            throw new Error(errMsgFinal);
        }
    } catch (error) {
        console.log(`${window.logPrefix()}${errMsg}: ${error.stack || error.message}`);
        throw error;
    }
};

Web3Helper.trackTxn = async (txnId, from, to, decodedData, transaction, simOutput) => {
    const errMsg = 'Failed to track txn';
    try {
        window.hideToast();
        const payload = {
            txnId,
            from,
            to,
            decodedData,
            transaction,
            simEventsInfo: simOutput ? simOutput.simEventsInfo : undefined,
        };
        const accessTokenToUse = await window.authManager.checkRefreshGetAccessTokenAsync();
        window.onTx();
        const response = await fetch(
            `${ConstantsCommon.API_URL}/txn/track`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessTokenToUse}`,
                },
                body: JSON.stringify(payload),
            },
        );
        window.onRx();
        const statusCode = response.status;
        console.log(`${window.logPrefix()}trackTxn statusCode [${statusCode}]`);
        if (statusCode === 200) {
            const data = await response.json();
            const txnInfo = data.txnInfo;
            // orderBookManager.upsertTxnConfirmation(txnInfo);
            window.txnManager.upsertTxnConfirmation(txnInfo);
            window.txnManager.refreshUserTxns();
        } else if (statusCode === 401) {
            window.authManager.handle401Redirect();
        } else {
            // console.log(`${window.logPrefix()}${errMsg}`);
            // showToast(errMsg);
            // logError(response);
            // NOTE: this means we cannot show the intent of the TXN, but as soon
            // as it makes it on-chain, txn will appear in UI
            await window.authManager.showDataErrorToast(response, errMsg);
        }
    } catch (error) {
        console.log(`${window.logPrefix()}${errMsg}: ${error.stack || error.message}`);
        window.showToast(errMsg);
    }
};

Web3Helper.getPreSendTxnOpts = (isUniswap) => {
    let includeSimOuput;
    let includeRawTxn;
    if (window.web3Metamask) {
        if (isUniswap) {
            includeSimOuput = true;
        } else {
            includeSimOuput = false;
        }
        includeRawTxn = false;
    } else if (window.ledgerAppEth) {
        includeSimOuput = true;
        includeRawTxn = true;
    } else if (window.bbWallet) {
        includeSimOuput = true;
        includeRawTxn = true;
    } else {
        throw new Error('Internal error: both ledgerAppEth and web3Metamask are undefined!');
    }
    return {
        includeSimOuput,
        includeRawTxn,
    }
};

Web3Helper.sendTxnAndTrack = async (txn, from, to, decodedData, rawTxnInfo, simOutputIn, simOutputPrepared = false) => {
    if (window.web3Metamask) {
        // uncomment to test ledger from MM
        // const {
        //     txnHex: _txnHex,
        //     unsignedTransactionHex: _unsignedTransactionHex,
        // } = await Web3Helper.getRawTxn(txn);

        // simOutput is only for uniswap transactions which we cannot get events from decodedData, so we use simOutput
        let simOutput;
        if (!simOutputPrepared) {
            // Haven't found a use-case where we need to use simOutput for non prepared flow
            // uncomment when needed.
            // const estimatedGasFactorBp = $('#estimated-gas-factor').val();
            // const {
            //     baseFeePerGas: _baseFeePerGas,
            //     txnHex: _txnHex,
            //     simOutput: simOutputTmp,
            // } = await Web3Helper.getRawTxn(txn, true, estimatedGasFactorBp);
            // simOutput = simOutputTmp;
        } else {
            simOutput = simOutputIn;
        }
        const txnId = await window.web3Metamask.currentProvider.request({
            method: 'eth_sendTransaction',
            params: [txn],
        });
        console.log(`${window.logPrefix()}txnId [${txnId}]`);
        const transaction = await window.web3Metamask.currentProvider.request({
            method: 'eth_getTransactionByHash',
            params: [txnId],
        });
        console.log(`${window.logPrefix()}transaction [${JSON.stringify(transaction).substring(0, 100)}]`);
        // deliberately leave out await to run in background, so it will not hold up UI
        Web3Helper.trackTxn(txnId, from, to, decodedData, transaction, simOutput);
        return true;
    } else if (window.ledgerAppEth) {
        return window.bobbobLedger.sendTxnAndTrack(txn, decodedData, rawTxnInfo, simOutputIn);
    } else if (window.bbWallet) {
        return window.bobbobBbwallet.sendTxnAndTrack(txn, decodedData, rawTxnInfo, simOutputIn);
    } else {
        throw new Error('Internal error: both ledgerAppEth and web3Metamask are undefined!');
    }
};

Web3Helper.deploySendAsync = async (contract, contractInfo) => {
    // MM has bug when gas is not specified, add one for now until it is fixed in MM.
    // https://github.com/MetaMask/metamask-extension/pull/22374
    return new Promise((resolve, reject) => {
        contract.deploy({
            data: contractInfo.bin,
            arguments: contractInfo.arguments,
        }).send({
            from: contractInfo.from,
            gas: contractInfo.estimatedGas,
        }, (error, hash) => {
            if (error) {
                return reject(error);
            }
            return resolve(hash);
        });
    });
};

Web3Helper.deployAndTrack = async (contract, contractInfo, simOutput) => {
    const txnId = await Web3Helper.deploySendAsync(contract, contractInfo);
    console.log(`${window.logPrefix()}txnId [${txnId}]`);
    const transaction = await window.web3Metamask.currentProvider.request({
        method: 'eth_getTransactionByHash',
        params: [txnId],
    });
    console.log(`${window.logPrefix()}transaction [${JSON.stringify(transaction).substring(0, 100)}]`);
    // deliberately leave out await to run in background, so it will not hold up UI
    Web3Helper.trackTxn(txnId, contractInfo.from, contractInfo.to, contractInfo.decodedData, transaction, simOutput);
};

Web3Helper.metaMaskSignTypedDataV3Async = (from, data, web3Metamask) => { return new Promise((resolve, reject) => { return Web3Helper.doMetaMaskSignTypedDataV3(from, data, resolve, reject, web3Metamask); }); };

Web3Helper.doMetaMaskSignTypedDataV3 = (from, data, resolve, reject, web3Metamask = undefined) => {
    const params = [from, JSON.stringify(data)];
    const method = 'eth_signTypedData_v4';
    const web3MetamaskToUse = web3Metamask || window.web3Metamask;
    web3MetamaskToUse.currentProvider.sendAsync({
        method,
        params,
        from,
    }, (err, result) => {
        if (err) {
            console.log(err);
            let errorMsg;
            // if (err.code === -32603) {
            if (err.message.indexOf('must match the active chainId') > -1) {
                errorMsg = `Please select the ${ConstantsCommon.chainName} blockchain in MetaMask`;
            } else {
                errorMsg = `Message not signed${err.code ? ` [${err.code}]` : ''}`;
            }
            return reject(new Error(errorMsg));
        }
        if (result.error) {
            console.log(`${window.logPrefix()}ERROR: `, result);
            window.showToast(result.error.message);
            return reject(new Error(result.error.message));
        }
        const sig = result.result;
        console.log(`${window.logPrefix()}sig [${sig}]`);
        const recovered = bobbobAuth.sigUtil.recoverTypedSignature({
            data,
            signature: sig,
            version: bobbobAuth.sigUtil.SignTypedDataVersion.V4,
        });
        console.log(`${window.logPrefix()}recovered [${recovered}]`);

        if (recovered.toLowerCase() === from.toLowerCase()) {
            console.log(`Successfully recovered signer as ${from}`);
            return resolve(sig);
        }
        console.log(`Failed to verify signer when comparing ${JSON.stringify(result)} to ${from}`);
        // showToast('Failed to verify signer');
        return reject(new Error('Failed to verify signer'));
    });
};

Web3Helper.gnosisSignTypedDataV3Async = (from, data, web3Gnosis, appsSdk) => { return new Promise((resolve, reject) => { return Web3Helper.doGnosisSignTypedDataV3(from, data, resolve, reject, web3Gnosis, appsSdk); }); };

Web3Helper.doGnosisSignTypedDataV3 = (from, data, resolve, reject, web3Gnosis = undefined, appsSdk = undefined) => {
    const params = [from, JSON.stringify(data)];
    const method = 'eth_signTypedData_v4';
    const web3GnosisToUse = web3Gnosis || window.web3Gnosis;
    web3GnosisToUse.currentProvider.send({
        method,
        params,
        from,
    }, (err, result) => {
        if (err) {
            console.log(err);
            let errorMsg;
            // if (err.code === -32603) {
            if (err.message.indexOf('must match the active chainId') > -1) {
                errorMsg = `Please select the ${ConstantsCommon.chainName} blockchain in MetaMask`;
            } else {
                errorMsg = `Message not signed${err.code ? ` [${err.code}]` : ''}`;
            }
            return reject(new Error(errorMsg));
        }
        if (result.error) {
            console.log(`${window.logPrefix()}ERROR: `, result);
            window.showToast(result.error.message);
            return reject(new Error(result.error.message));
        }
        const sig = result.result;
        console.log(`${window.logPrefix()}sig [${sig}]`);

        // local calling contract to verify
        // const magicValue = '0x1626ba7e';
        // const contract = gnosisSafeContract(wallet, provider);
        // const _signature = '0x';
        // const response = await contract.isValidSignature(msgHash, _signature);

        // // check if response equals EIP-1271 magic value
        // if(response === magicValue) {
        //    ...
        // }
        // local mimic contract verification
        // const eip712MessageHashBuffer = bobbobAuth.sigUtil.TypedDataUtils.eip712Hash(data, bobbobAuth.sigUtil.SignTypedDataVersion.V4);
        // const eip712MessageHash = `0x${eip712MessageHashBuffer.toString('hex')}`;

        // const dataGnosis = {
        //     domain: {
        //         chainId: ConstantsCommon.chainId,
        //         verifyingContract: from,
        //     },
        //     message: {
        //         message: eip712MessageHash,
        //     },
        //     // Refers to the keys of the *types* object below.
        //     primaryType: 'SafeMessage',
        //     types: {
        //         EIP712Domain: [
        //             { name: 'chainId', type: 'uint256' },
        //             { name: 'verifyingContract', type: 'address' },
        //         ],
        //         SafeMessage: [{ name: 'message', type: 'bytes' }],
        //     },
        // };
        // const eip712SafeMessageHashBuffer = bobbobAuth.sigUtil.TypedDataUtils.eip712Hash(dataGnosis, bobbobAuth.sigUtil.SignTypedDataVersion.V4);
        // const eip712SafeMessageHash = `0x${eip712SafeMessageHashBuffer.toString('hex')}`;
        // console.log(`${window.logPrefix()}eip712SafeMessageHash [${eip712SafeMessageHash}]`);

        // const rsv = bobbobAuth.UtilsCommonMin.getRsvFromSignature(web3, sig);
        // const recovered = bobbobAuth.sigUtil.recoverTypedSignature({
        //     data: dataGnosis,
        //     signature: sig,
        //     version: bobbobAuth.sigUtil.SignTypedDataVersion.V4,
        // });
        // console.log(`${window.logPrefix()}recovered [${recovered}]`);

        // if (recovered.toLowerCase() === rsv.r.toLowerCase()) {
        //     console.log(`Successfully recovered signer as ${from}`);
        //     return resolve(sig);
        // }
        // use lib
        const isSigned = appsSdk.safe.isMessageSigned(data, sig);
        if (isSigned) {
            console.log(`Successfully recovered signer as ${from}`);
            return resolve(sig);
        }
        console.log(`Failed to verify signer when comparing ${JSON.stringify(result)} to ${from}`);
        // showToast('Failed to verify signer');
        return reject(new Error('Failed to verify signer'));
    });
};

module.exports = {
    Web3Helper,
};
